.container {
   border-radius: 2em;
   border-top-right-radius: 0;
   max-height: 50vh;
}

.container ul {
   list-style: none;
   width: 5em;
   margin: 0 auto;
}

.container li {
  display: block;
  text-align: center;
}

.container li:first-child {
   text-align: right;
 }

 .container li:last-child {
  text-align: left;
 }

.container img {
   border: 0.2em solid #343d56;
   border-radius: 0.5em;   
}

.container img:first-child {
   height: 4em;
   top: 6vh;
   right: 24vw;
 
}

.container img:nth-child(2){
   top: 6vh;
   right: 24vw;
}

.container img:nth-child(3){
   top: 6vh;
   right: 24vw;
}

