.container {
    font-size: 0.6em;
    width: 7.5%;
    display: inline-block !important;   
    padding: 2.5% 0 2.5% 2.5%;
    height: 49vh;
    margin-top: 80px;
}

.ul {
    list-style: none;
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-inline-start: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between; 
}

.ul li {
    text-align: center;
    display: inline-block;
    transform: rotate(270deg);
}

.ul li span {
    font-size: 0.7em;
    vertical-align:middle;
    color: #808DAD; 
}

.ul li:first-child {
    margin-top: 2em; 
}

.ul li:last-child {
    text-align: center;
    display: inline-block;
    transform: rotate(0deg);
    margin-top: 2em;
}

.container svg:hover {
    transform: scale(1.2)
}

@media (max-width: 1019px) {
    .container {
        margin-top: 65px;
    }
}

/* 2436x1125px at 458ppi IPhone  12 */
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 

    .container {
        padding: 2.5% 0 2.5% 0;
    }

    .ul {
        align-content: center;
    }

    .ul li:nth-child(7){
        width: 4em;
        height: 14px;
        margin-left: 7px;
    }
}