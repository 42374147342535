.container {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    margin: 6vh 5% 0 10%;
}

.flexItemLeft {
    flex: 50%;
    align-items: center;
    }
    
.flexItemRight {
flex: 50%;
}

.title {
    text-align: left;
    width: 100%;
    color: #EB4A4A;
    margin-bottom: 1em;
    font-size: 0.5em;
}

.subTitle {
    font-size: 0.8em;
    text-align: left;
    width: 100%;
    margin-top: 0.6em;
}

.p {
    margin-top: 1em;
    font-size: 10px;
    text-align: left;
    color: #808dad;
    margin-bottom: 2em;
}

.ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between; 
    height: 3em;
    margin-bottom: 1em;
}

.ul li {
    list-style: none;
    font-size: 0.6em;
    text-align: left;
}

.ul li p,
.ul li a {
    margin-left: 1em;
    vertical-align: middle;
}

.mailStatus {
    text-align: left;
    font-size: 0.5em;
    margin-top: 2.5em;
}

.mailSuccess {
    color: rgb(101, 173, 101);
}

.mailError {
    color: rgb(158, 74, 74);
}


/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
    .flexItemRight, .flexItemLeft  {
        flex: 100%;
    }
}

/* 2436x1125px at 458ppi IPhone  12 */
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 

    .flexItemLeft {
        margin-bottom: 2em;
    }

     .ul {
         height: 40%;
     }   
}
