.container {
    width: 85%;
    margin: 6vh 5% 0 10%;
    
}

.projectsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: scroll;
}

@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 

    .projectsContainer {
        margin-top: 2em;
        height: 55.9vh;
        overflow: scroll;
    }
}