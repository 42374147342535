.App {
  text-align: center;
  font-size: 12px;
}

.App-header {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.outerContainer {
  max-width: 1200px;
  height: 80vh;
  width: 86%;
  margin: 10vh 8vw;
  background-color: rgb(34, 43, 64);
  border: 3px solid #343d56;
  border-radius: 1em; 
  -webkit-box-shadow: 0px 0px 22px -3px rgba(0,0,0,0.53); 
  box-shadow: 0px 0px 22px -3px rgba(0,0,0,0.53);
}

.innerContainer {
  max-width: 1166px;
  height: 95%;
  width: 98%;
  margin: 2vh 1vw;
  background-color: #0E1630;
  border-radius: 1em; 
  -webkit-box-shadow: 0px 0px 22px -3px rgba(0,0,0,0.53); 
  box-shadow: 0px 0px 22px -3px rgba(0,0,0,0.53);
}

li.active a{
  color: #EB4A4A !important;
  padding-bottom: 3px;
  border-bottom: 1px solid #EB4A4A;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 0.7em;
  font-weight: 500;
  margin-bottom: 1em;
}

a {
  text-decoration: 
  none;
}

@media (max-width: 920px) {
  h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 644px) {
  h1 {
    font-size: 1.3em;
  }

  h2 {
    font-size: 14px;
  }
}

/* 2436x1125px at 458ppi IPhone  12 */
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 

      h1 {
        font-size: 1.1em;
      }
    
      .App-header {
        min-height: 85.5vh;
        width: 100%;
        justify-content:flex-start;
      }
    
      .outerContainer {
        height: 80vh;
        width: 90%;
        margin: 5%;
        background-color: rgb(34, 43, 64);
        border: 3px solid #343d56;
        border-radius: 1em; 
        -webkit-box-shadow: 0px 0px 22px -3px rgba(0,0,0,0.53); 
        box-shadow: 0px 0px 22px -3px rgba(0,0,0,0.53);
      }

      .innerContainer {
        max-width: 1166px;
        height: 76.4vh;
        width: 94%;
        margin: 1.8vh 3%;
      
      }
    
    }



