.container {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    margin: 6vh 5% 0 10%;
}

.flexItemLeft {
    flex: 50%;

    align-items: center;
    }
    
    .flexItemRight {
    flex: 50%;
    
    }

.title {
    text-align: left;
    width: 100%;
    color: #EB4A4A;
    margin-bottom: 0;
    font-size: 0.5em;
}

.subTitle {
    font-size: 0.8em;
    text-align: left;
    width: 100%;
    margin-top: 0.6em;
}

.p {
    margin-top: 1em;
    font-size: 10px;
    text-align: left;
    color: #808DAD;
    margin-bottom: 2em;
}

.buttonContainer {
    text-align: left;
}

.buttonContainer button:first-child{
    margin-right: 10px;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
    .flexItemRight, .flexItemLeft  {
        flex: 100%;
    }

    .flexItemRight {
        display: none;
     }
}

/* 2436x1125px at 458ppi IPhone  12 */
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
    
    .flexItemRight {
       display: none;
    }
}