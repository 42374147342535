.logo { 
    
    width: auto;
    vertical-align: middle;
    height: 26px;
    margin: 19.5px 0;
}

@media (max-width: 841px) {
    .logo {
        max-width: 55px;
        margin-left: 7px;
    }
}