.nav {
    width: 84.3%;
    border-bottom: 0.2px solid #808DAD;
    position: absolute;
    height: 65px;
    max-width: 1166px;
}

.logoContainer {
    display: inline-block;
    height: 100%;
}

.ulContainer {
    width: 80%;
    display: inline-block;
    vertical-align: top;
}


.nav ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    height: 65px;
    width: 90%;
    margin: 0 5%;
}

.nav ul li {
    font-size: 14px;
    margin-right: 1.5em;
    color: #808DAD;
}

.nav ul li a {
    text-decoration: none;
    color: #808DAD;
    font-weight: 500;
    transition: color 0.3s ;
}

.nav ul li a:hover {
    color: #fff;
}



.nav .phone {
    color: #EB4A4A !important;
}

@media (max-width: 1019px) {

    .nav a {
        margin-right: 3%;

    }

    .nav ul li {
        margin-right: 0.75em;
        font-size: 12px;
    }

    .nav ul li:nth-child(6),
    .nav ul li:nth-child(7)  {
        display: none;
    }
}

@media (max-width: 365px) {
    .nav ul li {
        margin-right: 0.75em;
        font-size: 10px;
    }
}


/* 2436x1125px at 458ppi IPhone  12 */
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 

    .flexItemLeft {
        margin-bottom: 2em;
    }

     .ul {
         height: 40%;
     }   
}
