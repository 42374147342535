* {
    margin: 0;
    padding: 0;
}

.circular {
    margin: 10px;
    height: 100px;
    width: 100px;
    position: relative;
    display: inline-block;
}

.circular .inner {
    background-color: #0E1630;
    position: absolute;
    height: 92px;
    width: 92px;
    margin: 4px 0 0 4px;
    border-radius: 100%;
    z-index: 5;
}

.circular .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: white;
    font-size: 0.5em;
    font-weight: 500;
}

.level {
    color: #808DAD;
    font-weight: 500;
    width: 92px;
    font-size: 0.8em;
}

.circular .bar
{
    position: absolute;
    height: 100%;
    width: 100%;
    background: #808DAD;
    border-radius: 100%;
    clip: rect(0px, 100px, 100px, 50px);
}

.circular .bar .progress{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    clip: rect(0px, 50px, 100px, 0px);
    background: #EB4A4A;
}

.circular .left .progress {
    z-index:1;
    animation: left 2s linear both;
}

@keyframes left {
    100%{
        transform: rotate(180deg);
    }
}

.circle .right {
    transform: rotate(180deg);
    z-index:3;
}

.circle .right .progress{
animation: right 1s linear both;
animation-delay:2s;
}

@keyframes right{
    100%{
      transform: rotate(90deg);
    }
}