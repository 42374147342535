.h1 {
    text-align: left;
    width: 100%;
    color: #fff;
    margin-top: 0.1em;
    margin-bottom: 0;
    text-transform: uppercase;
}

.buttonContainer {
    text-align: left;
}

.buttonContainer button:first-child{
    margin-right: 10px;
    margin-bottom: 1em;
}

.subTitle {
    text-align: left;
    width: 100%;
    color: #808DAD;
    margin-top: 0.6em;
}

@media (max-width: 613px) {
    .buttonContainer button:first-child{
        margin-right: 0;
        margin-bottom: 1em;
        display: block;
    }
}

@media (max-width: 415px) {
    .subTitle {
        font-size: 12px;
    }
}