.container {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    margin: 6vh 5% 0 10%;
}

.flexItemLeft {
flex: 50%;
height: 49vh;
align-items: center;

}

.flexItemRight {
flex: 50%;
height: 49vh;

}

.flexItemRightUpper {
    height: 15vh;;
}

.flexItemRightUpper ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flexItemRightUpper ul li {
    list-style: none;
}

.flexItemRightUpper ul p {
    font-size: 0.4em;
}

.flexItemRightLower {
    height: 20vh;
}

.barContainer {
    height: 90%;
    overflow: hidden;
}

.skillsList {
    height: 90%;
    padding-inline-start: 0;
    padding-inline-end: 2em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.skillsList li{
    list-style: none;
    font-size: 0.4em;
    text-align: left;
    color: #fff;
}

.spanLabel {
    width: 40%;
    display: inline-block;
}

.spanProgress {
    width: 60%;
    display: inline-block;
    overflow: hidden;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
    .flexItemRight, .flexItemLeft  {
        flex: 100%;
    }

    .flexItemLeft {
        height: 30%;
    }

    .skillsList {
        height: 100%;
        padding-inline-end: 0;
        margin-bottom: 1em;
    }

    .flexItemRight {
        height: 25vh;
    }

    .flexItemRightUpper {
        height: 37%;
    }
}

/* 2436x1125px at 458ppi IPhone  12 */
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
    
    .flexItemLeft {
        height: 30%;
    }

    .skillsList {
        height: 100%;
        padding-inline-end: 0;
        margin-bottom: 1em;
    }

    .flexItemRight {
        height: 25vh;
    }

    .flexItemRightUpper {
        height: 37%;
    }

}

